/*
 * @Date: 2023-04-10 14:07:05
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-25 17:01:31
 * @FilePath: \P1-M3-QRP-CODE\pages\m3-001.js
 */
import React, { useEffect } from "react";
import Header from "@/style10/HeaderForm"; // 头部 组件
import Central from "@/style10/Central"; // 表单 组件
import Banner from "@/style10/Banner";
import RegisterForm from "@/style10/RegisterForm";
import Description from "@/style10/Description";
import Message from "@/popup/Message";
import Footer from "@/Footer"; // 底部内容
import { ApiPort } from "$ACTIONS/API";
import { useAuth } from "$UTILS/hooks/useAuth";
import { show } from "@ebay/nice-modal-react";
import useBanner from "$UTILS/hooks/useBanner";
import {checkAffQueryString} from "../actions/util";
import setupTraces from '$CONFIG/index';

const pageName = 'm3-001';
setupTraces(pageName);

const HayHar = () => {
	const bannerList = useBanner({
		url: ApiPort.Banner1,
		fallbackImage: {
			desktop: "/static/images/m3-001/1.HigherRate.jpg",
			mobile: "/static/images/m3-001/1.HigherRate.jpg",
			cmsImageUrlWebAlt : 'default WEB Alt',
			cmsImageUrlMobileAlt : 'defalult Mobile Alt'
		},
	});

	const hostname = typeof window === "undefined" ? "" : window.location.hostname;

	// let traceConfig = {};
	// for (const key in traceConfigs) {
	//     if (key === pageName) {
	//         traceConfig = traceConfigs[key];
	//         break;
	//     }
	// }

	// usePiwik(traceConfig.piwik);
	// useEagleEye();
	// useIovation();

	console.log("pageName", pageName);
	// console.log("traceConfig", traceConfig)

	useEffect(() => {
		checkAffQueryString(window.location.search);
	},[])

	return (
		<div className={`style10 ${pageName}`}>
			{/* 顶部 */}
			<Header
				onBefore={() => {
					// piwik 追踪
					global.globalGtag("Style10_QRP", "Login form", "Submit_login", "event");
				}}
				onSuccess={(result) => {
					const text = result?.message || "Đăng nhập thành công";
					show(Message, { title: "Đăng nhập thành công", text, buttonProps: { hidden: true } });
					if(result.url) {
                        window.location.replace(result.url);
                    }
				}}
				onError={(error) => {
					const text = error?.message || "Đăng nhập thất bại";
					show(Message, { title: "Đăng nhập thất bại", text });
				}}
			/>
			<Banner bannerList={bannerList} />
			{/* 表单 */}
			<Central title="BETWAY RA MẮT TẠI VIỆT NAM">
				<RegisterForm
					onBefore={() => {
						// piwik 追踪
						global.globalGtag('QRP', 'Submit', 'Submit_QRP', 'event')
					}}
					onSuccess={(result) => {
						const text = result?.message || 'Đăng ký thành công';
						// show(Message, { title: "Đăng ký thành công", text, buttonProps: { hidden: true } });
						if (result.url) {
							window.location.replace(result.url);
						}
					}}
					onError={(error) => {
						const text = error?.message || "Đăng ký thất bại";
						show(Message, { title: "Đăng ký thất bại", text });
						console.log("error", error);
					}}
				/>
			</Central>
			{/* 底部 */}
			<Footer />
		</div>
	);
};

export default HayHar;
