/*
 * @Date: 2023-04-10 14:07:05
 * @LastEditors: simLarry
 * @LastEditTime: 2023-06-01 23:05:07
 * @FilePath: \P1-M3-QRP-CODE\utils\hooks\useCheckName.js
 */
import { useRequest } from 'ahooks';
import { fetchRequest } from '$CONFIG/Fatch.config';
import { Cookie, getBrowserVersion } from '$ACTIONS/util';
import { ApiPort } from '$ACTIONS/API';

const getCheckName = ({  username, }) => {
    return fetchRequest(ApiPort.CheckName, 'GET', `&memberCode=${username}`);
}


const useCheckName = ({onBefore, onSuccess, onError}) => {
    return useRequest(getCheckName, {
        debounceInterval: 500,
        manual: true,
        onBefore: () => {
            if (typeof onBefore === 'function') {
                onBefore();
            }
            console.log("onBefore")
        },
        onSuccess: (result, params) => {
            if (typeof onSuccess === 'function') {
                onSuccess(result, params);
            }
            console.log("onSuccess", result, params)
        },
        onError: (error) => {
            if (typeof onError === 'function') {
                onError(error);
            }
            console.log("onError", error)
        },
    });
}

export default useCheckName;
