/*
 * @Date: 2023-04-10 14:07:05
 * @LastEditors: simLarry
 * @LastEditTime: 2023-06-01 23:10:05
 * @FilePath: \P1-M3-QRP-CODE\components\style10\RegisterForm.js
 */
import React, { useMemo , useState , useEffect} from 'react';
import { Button } from 'antd';
import { RegName, RegPassword, RegPhone, RegEmail } from '$CONFIG/common/reg';
import { useForm } from "react-hook-form";
import useRegister from '$UTILS/hooks/useRegister';
import { getBirthdayYears, getBirthdayDays , getBirthDayMonth } from '$UTILS';
import Loading from '@/Loading';
import useURLs from "$UTILS/hooks/useURLs";
import useCheckName from '$UTILS/hooks/useCheckName';
import { object } from 'zod';

const years = getBirthdayYears();

let oldValues = {}; //用於判斷是否提交piwik

const piwikMapping = {
	username: 'Username_input',
	password: 'Password_input',
	mobile: 'phone_input',
	email: 'Email_input',
}

const RegisterForm = ({ onBefore, onSuccess, onError }) => {
	const aff = '';
	const { data } = useURLs();
	const { mainSiteUrl = '', liveChatUrl = '' } = data || {};
	const Hyperlink = mainSiteUrl ? new URL(`/vn/rule/?keyword=tnc`, mainSiteUrl).href : "https://betwayvn.com/vn/rule/?keyword=tnc"
	const [isShowPass , setIsShowPass] = useState(false);
	const [isFormValid , setIsFormValid] = useState(false);
	const { loading, run } = useRegister({ onBefore, onSuccess, onError });
	const { run : deliver , data1 } = useCheckName({ onBefore, onSuccess, onError });
	const { register, getValues, handleSubmit, formState: { errors }, watch, setValue } = useForm({
		mode: 'all',
		defaultValues: {
			birthday: {
				year: '',
				month: '',
				day: ''
			},
			gender: 'Male'
		}
	});
	const currentYear = watch('birthday.year');
	const currentMonth = watch('birthday.month');
	const days = useMemo(() => getBirthdayDays(currentYear, currentMonth), [currentYear, currentMonth]);
	const month = useMemo(() =>getBirthDayMonth(currentYear, currentMonth), [currentYear, currentMonth]);
	
	const handleFormSubmit = data => {
		const { username, password, mobile, birthday, gender, email } = data;
		run({
			username,
			password,
			mobile: `${mobile}`,
			email,
			// gender,
			// dateOfBirth: `${birthday.year}-${birthday.month}-${birthday.day}`,
		})
	}

	//focus時，存下當前數值
	const handleFocus = (fieldName) => {
		const currentVal = getValues(fieldName);
		console.log(`${fieldName} onFocus with ${currentVal}`);
		oldValues[fieldName] = currentVal;
	}

	//blur時，從之前存下的值，判斷是否有變更
	const handleBlurPiwik = (fieldName) => {
		const oldVal = oldValues[fieldName];
		const currentVal = getValues(fieldName);
		if (currentVal && (currentVal != oldVal)) { //要有數值+有變更才需要提交piwik
			console.log(`${fieldName} REALBlur from ${oldVal} to ${currentVal}`)
			const eventName = piwikMapping[fieldName];
			global.globalGtag('Style10_QRP', 'Registration form', eventName, 'input')
			// fieldName === 'username' && deliver({username : getValues('username')})
		}
	}
	/**
	 * 校验 电话号码 只能输入 数字
	 * @param {*} e 
	 */
	const handleNumberInput = (e) =>{
		e.target.value = e.target.value.replace(/[^0-9]/g,"").replace(/^0{1,}/ig,'');
	}
	

	useEffect(()=>{
		// button 解除 disable 
		setIsFormValid(Object.keys(errors).length === 0 && getValues('mobile') !== '' && getValues('username') !== '' && getValues('password') && getValues('email'));
	},[errors.mobile , errors.username , errors.password , errors.email])
	return (
		<form className="form-wrapper" onSubmit={handleSubmit(handleFormSubmit)}>
			<div className="form-block">
				<div className="login-item grid-cols-4">
					<label className="form-label">Tên Đăng Nhập​</label>
					<input type="text" className="input-control col-span-4" maxLength={14} placeholder="Vui lòng điền tên đăng nhập"
								 onFocus={() => handleFocus('username')}
								 {...register("username", {
									 required: "Tên đăng nhập không được để trống", pattern: { value: RegName, message: 'Tên đăng nhập phải chứa 6-14 ký tự gồm chữ và số' }
									 , onBlur: () => handleBlurPiwik('username')
								 })} />
					{errors.username && <label className="error-txt col-span-4 col-start-3">{errors.username.message}</label>}
				</div>
				<div className="login-item grid-cols-4">
					<label className="form-label">Mật Khẩu​</label>
					<input type={isShowPass ? 'text' : 'password'} className="input-control col-span-4" maxLength={20} name="password" placeholder="Vui lòng điền mật khẩu"
								 onFocus={() => handleFocus('password')}
								 {...register("password", {
									 required: "Mật khẩu không được để trống", pattern: { value: RegPassword, message: 'Mật khẩu phải chứa từ 6-14 chữ và số (Có thể bao gồm các ký tự đặc biệt như ^#$@)' }
									 , onBlur: () => handleBlurPiwik('password')
								 })} />
					{isShowPass ? <img className='eye-icon' src='/static/images/open_eye.png' onClick={()=>{
						setIsShowPass(false);
					}} /> : <img src='/static/images/close_eye.png' className='eye-icon' onClick={()=>{
						setIsShowPass(true);
					}}/>}
					{errors.password && <label className="error-txt col-span-4 col-start-3">{errors.password.message}</label>}
				</div>
				<div className="login-item grid-cols-4">
					<label className="form-label">Số Điện Thoại​</label>
					<div className="input-control disabled" name="phone" >
						<img className='icon-vn' src='/static/images/flag-thb.png' />	
						{'+84'}
					</div>
					<input type="text" className="input-control col-span-3" maxLength={10} name="mobile" placeholder="Vui lòng điền số điện thoại"
								 onFocus={() => handleFocus('mobile')}
								 onInput={handleNumberInput}
								 {...register("mobile", {
									 required: "Số điện thoại không được để trống", pattern: { value: RegPhone, message: 'Số điện thoại cần có 9 chữ số và không điền số 0 ở đầu' }

									 , onBlur: () => handleBlurPiwik('mobile')
								 })} />
					{errors.mobile && <label className="error-txt col-span-4 col-start-3">{errors.mobile.message}</label>}
				</div>
				<div className="login-item grid-cols-4">
					<label className="form-label">Email</label>
					<input type="email" className="input-control col-span-4" maxLength={50} name="email" placeholder="Vui lòng nhập địa chỉ Email"
								 onFocus={() => handleFocus('email')}
								 {...register("email", {
									 required: "Email không được để trống ", pattern: { value: RegEmail, message: 'Định dạng email không hợp lệ' }
									 , onBlur: () => handleBlurPiwik('email')
								 })} />
					{errors.email && <label className="error-txt col-span-4 col-start-3">{errors.email.message}</label>}
				</div>
				{/*<div className="login-item grid-cols-4" data-key="DOB">
					<label className="form-label">Ngày sinh</label>
					<select className="input-control birthday" {...register("birthday.day", { required: true, onChange: () => { global.globalGtag('Style10_QRP', 'Registration form', 'BirthdayDay_input', 'input') } })}>
						<option value="" disabled>Ngày</option>
						{days.map(value => <option key={value} value={String(value).padStart(2, "0")}>{String(value).padStart(2, "0")}</option>)}
					</select>
					<select className="input-control birthday" {...register("birthday.month", { required: true, onChange: () => { setValue("birthday.day", ""), global.globalGtag('Style10_QRP', 'Registration form', 'BirthdayMonth_input', 'input') } })}>
						<option value="" disabled>Tháng</option>
						{month.map(value => <option key={value} value={String(value).padStart(2, "0")}>{String(value).padStart(2, "0")}</option>)}
					</select>
					<select className="input-control birthday" {...register("birthday.year", { required: true, onChange: () => { setValue("birthday.day", ""), global.globalGtag('Style10_QRP', 'Registration form', 'BirthdayYear_input', 'input') } })}>
						<option value="" disabled>Năm</option>
						{years.map(value => <option key={value} value={value}>{value}</option>)}
					</select>
					{errors.birthday && <label className="error-txt col-span-3 col-start-2">Ngày tháng năm sinh không được bỏ trống</label>}
				</div>*/}
				{/*<div className="login-item grid-cols-4" data-key="GENDER">
					<label className="form-label">Giới tính</label>
					<select className="input-control col-span-3" id="inputState" name="gender" {...register("gender", { required: true, onChange: () => { global.globalGtag('Style10_QRP', 'Registration form', 'gender_input', 'input') } })}>
						<option value="Male">Nam</option>
						<option value="Female">Nữ</option>
					</select>
				</div>*/}
				<Button htmlType='submit' className="submit" disabled={!isFormValid || loading}>Đăng Ký</Button>
				<div style={{fontSize:'14px'}}>Bằng việc nhấp vào nút “Đăng Ký” nghĩa là Bạn đã trên 18 tuổi và đồng ý với <a href={Hyperlink} style={{color:'#00A826',textDecoration:'none'}}>Điều Kiện Điều Khoản và Chính Sách  Bảo Mật tại Betway!</a></div>
				{loading && <Loading />}
			</div>
		</form>
	)
}

export default RegisterForm
